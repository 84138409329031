<template>
  <div
    class="layers"
    @pointermove="prevent"
    @pointerup="prevent"
  >
    <div class="icon" @click="handleOpen(true)">
      <svg version="1.1" id="Icons" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 32 32">
        <polygon class="st0" points="16,4 3,12 16,20 29,12 "/>
        <polyline class="st0" points="6.3,18 3,20 16,28 29,20 25.8,18 "/>
      </svg>
    </div>
    <div class="panel" v-if="opened">
      <div class="title">
        <h2>Détails de la carte</h2>
        <Close  @click="handleOpen(false)" />
      </div>
      <label>
        <input
          type="checkbox"
          v-model="showLines"
          @change="handleChange"
        />
        Contours
      </label>
    </div>
  </div>
</template>

<script>
import { inject, ref } from "vue";
import { showLines } from "@/pure/data.legislatives.gouv";
import Close from "@/components/Close.vue";
export default {
  name: 'LayersComponent',
  components: {
    Close
  },
  setup() {
    const graphics = inject('graphics');
    const opened = ref(false);
    const handleChange = () => {
      if(showLines.value) {
        graphics.output.components.map.showLines();
      } else {
        graphics.output.components.map.hideLines();
      }
    };
    const prevent = (e) => {
      e.preventDefault();
      e.stopPropagation();
    }
    const handleOpen = (value) => {
      opened.value = value;
    }
    return {
      opened,
      showLines,
      handleChange,
      handleOpen,
      prevent
    }
  }
}
</script>

<style scoped lang="scss">
.layers {
  position: fixed;
  right: 1.5vw;
  top: 6vw;
  z-index: 2;
  @include respond-to("xs-down") {
    top: 9vw;
    right: 2.5vw;
    display: none;
  }
}
.icon {
  width: 32px;
  height: 32px;
  padding: 10px;
  background-color: #f3ece0;
  // border-radius: 8px;
  border: 2px solid #202122;
  cursor: pointer;
}
.st0 {
  fill:none;
  stroke:#000000;
  stroke-width:2;
  stroke-linecap:round;
  stroke-linejoin:round;
  stroke-miterlimit:10;
}
.panel {
  position: absolute;
  top: 0;
  right: 0;
  padding: 12px;
  // background-color: #f3ece0;
  background-color: #d3c0af;
  border: 2px solid #202122;
  .title {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 12px;
  }
  h2 {
    margin: 0;
    font-size: 18px;
    white-space: nowrap;
  }
  label {
    white-space: nowrap;
  }
}
</style>
