<template>
  <div class="bulle">
  </div>
</template>

<script>
export default {
  name: 'MacaronComponent',
}
</script>

<style scoped lang="scss">
.bulle {
  width: 26px;
  height: 26px;
  border-radius: 50%;
  border-width: 2px;
  border-style: solid;
  border-color: var(--color);
  display: flex;
  align-items: center;
  justify-content: center;
  &:after {
    content: "";
    display: block;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    background-color: var(--color);
  }

}
</style>
