// let polygon = [
//     [5, 0]
//     [6, 4]
//     [4, 5]
//     [1, 5]
//     [1, 0]
//   ];
  
 export default function winding (polygon) {
    // From http://www.faqs.org/faqs/graphics/algorithms-faq/ "How do I find the orientation of a simple polygon?"
    // THIS SOMETIMES FAILS if the polygon is a figure 8, or similar shape where it crosses over itself
    
    // Take the lowest point (break ties with the right-most). 
    if (polygon.length < 3) {
      return true // A single point or two points can't be clockwise/counterclockwise
    }
    let previousPoint = polygon[0]
    let lowestPoint = polygon[1]
    let nextPoint = polygon[2]
    polygon.forEach((point, index)=>{
      if (point[1] > lowestPoint[1] || (point[1] === lowestPoint[1] && point[0] > lowestPoint[0])) { // larger y values are lower, in svgs
        // Break ties with furthest right
        previousPoint = polygon[(index-1) >= (0)                ? (index-1) : (polygon.length-1)]
        lowestPoint = polygon[index]
        nextPoint = polygon[(index+1)     <= (polygon.length-1) ? (index+1) : (0)]
      }
    })
    // Check the angle between the previous point, that point, and the next point.
    // If the angle is less than PI radians, the polygon is clockwise
    let angle = findAngle(previousPoint, lowestPoint, nextPoint)
    return angle < Math.PI
  }
  
  function findAngle(A,B,C) {
    var AB = Math.atan2(B[1]-A[1], B[0]-A[0]);
    var BC = Math.atan2(C[1]-B[1], C[0]-B[0]);
    if (AB < 0) AB += Math.PI*2
    if (BC < 0) BC += Math.PI*2
    return BC-AB;
  }