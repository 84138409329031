<template>
  <router-view/>
</template>

<style lang="scss">
@import '@/styles/index.scss';

body {
  color: #202122;
  font-family: "Roboto", sans-serif;
  touch-action: none;
  --color: #xd3c0af;
}

#graphics {
  position: fixed;
  top: 0px;
  right: 0px;
  left: 0px;
  z-index: 0;
  height: 100vh;
  background-color: #030c1e;
}
</style>
