import * as d3 from "d3";
import {
    Shape,
    ShapeGeometry,
    Float32BufferAttribute,
    Color,
    BufferGeometry,
    Path,
} from "three";
import * as BufferGeometryUtils from 'three/addons/utils/BufferGeometryUtils.js';
import winding from "@/pure/winding";
class ShapeFactory {
    projection = d3
        .geoConicConformal()
        .center([2.454071, 46.279229])
        .scale(3600)
        .translate([0, 0])
        .reflectY(true);

    geoPath = d3.geoPath(this.projection);

    constructor() {
    }
    project([longitude, latitude]) {
        const point = this.projection([longitude, latitude]);
        const [x, y] = point;
        return [
            x,
            y
        ];
    }
    createShape(featureGeometry, color, i = -2) {
        let shapearray = [];
        if ( featureGeometry.type === "MultiPolygon" && featureGeometry.coordinates[0].length > 1) {
            for (let P of featureGeometry.coordinates[0]) {
                const previousShape = shapearray[shapearray.length - 1];
                const windingOrder = winding(P);
                const shape = (!windingOrder) ? new Shape() : new Path();
                this.computeShape(shape, P);
                if (!windingOrder) {
                    shapearray.push(shape);
                }  else {
                    previousShape.holes.push(shape);
                }
            }
        } else {
            for (let P of featureGeometry.coordinates) {
                if (featureGeometry.type === "MultiPolygon") {
                    P = P[0];
                }
                const windingOrder = winding(P);
                const shape = (!windingOrder) ? new Shape() : new Path();
                this.computeShape(shape, P);
                if (!windingOrder) {
                    shapearray.push(shape);
                }  else {
                    shapearray[shapearray.length - 1].holes.push(shape);
                }
            }
        }
        let shapeGeo = new ShapeGeometry(shapearray);
        const pos = shapeGeo.getAttribute('position');
        const indices = new Array(pos.count).fill(i);
        shapeGeo.setAttribute('idx', new Float32BufferAttribute(indices, 1));

        const colors = [];
        for (let y = 0; y < pos.count; y++) {
            const col = new Color(color);
            colors.push(...col.toArray());
        }
        shapeGeo.setAttribute('color', new Float32BufferAttribute(colors, 3));
        return shapeGeo;
    }
    computeShape(shape, P) {
        let p0 = [P[0][0], P[0][1]];
        for (let i = 1; i < P.length; ++i) {
            let p1 = [P[i][0], P[i][1]];
            if (i === 1) {
                shape.moveTo(...this.project(p0));
            } else {
                shape.lineTo(...this.project(p0))
            }
            p0 = p1;
        }
        
    }
    createLine(featureGeometry) {
        var geometry = new BufferGeometry();
        const positions = [];
        for (let P of featureGeometry.coordinates) {
            if (featureGeometry.type === "MultiPolygon") {
                P = P[0];
            }
            let p0 = [P[0][0], P[0][1]];
            for (let i = 1; i < P.length; ++i) {
                let p1 = [P[i][0], P[i][1]];
                positions.push(...this.project(p0), 0.01, ...this.project(p1), 0.01);
                p0 = p1;
            }
        }
        geometry.setAttribute('position', new Float32BufferAttribute(positions, 3));
        return geometry;
    }
    mergeShapes(shapes) {
        return BufferGeometryUtils.mergeGeometries(shapes);
    }
}

export default new ShapeFactory();