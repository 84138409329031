<template>
  <div class="home">
    <h1 ref="title"><span class="long">ÉLÉCTIONS</span> <span>LÉGISLATIVES</span> <span>2024</span></h1>
    <Layers></Layers>
    <transition @enter="handleEnter" @leave="handleLeave" :css="false" mode="out-in">
      <div class="heads" ref="panel" v-if="sortedHeads.length > 0 && activeShape === null">
        <TransitionGroup name="list" tag="ul">
          <li
            :key="code"
            v-for="({ code, color, nuance, url, count }) in sortedHeads"
          >
            <a
              class="head"
              target="_blank"
              :href="url"
              :style="{
                '--color': color
              }"
            >
              <Macaron />
              <div>
                <h2>{{ nuance }}  <span class="small">({{ count }} {{ wording }}{{ count > 1 ? 's' : '' }})</span></h2>
              </div>
            </a>
          </li>
        </TransitionGroup>
      </div>
      <PanelData
        v-else-if="activeShape !== null"
        :name="focusLabel"
        :data="currentFocusData"
        :key="focusLabel"
      />

    </transition>
    <div
      class="sub-title" ref="nav"
      @pointerup="prevent"
    >
      <div v-if="activeShape === null">
        Liste en tête par 
        <span class="selection">
          <select v-model="currentShapeType" @change="handleChange">
            <option value="circonscription">Circonscription</option>
            <option value="departement" v-bind="departementAttributes">Département</option>
          </select>
          <span class="icon">▾</span> {{ wording }}
          
        </span>
        au 
        <span class="selection">
          <select v-model="currentRound" @change="handleChangeRound">
            <option value="1er">1er tour</option>
            <option value="2d" v-bind="secondAttributes">2d tour (seul)</option>
            <option value="2d1er" v-bind="secondAttributes">2d tour</option>
          </select>
          <span class="icon">▾</span> {{ currentRound === "1er" ? "1er tour" : "2d tour" }}
        </span>
      </div>
      <div v-else @pointerup="closeFocus">
        <span class="selection"><Close /> FOCUS</span> {{ focusLabel }}
      </div>
    </div>
    <Highlight
      v-if="highlightShape"
      :data="highlightShape"
      :style="{
        '--offsetX': offset.x,
        '--offsetY': offset.y,
        '--scale': offset.scale,
      }"
    />
    <div class="footer">
      Source: <a target="_blank" href="https://www.data.gouv.fr/fr/reuses/elections-legislative-2024-liste-en-tete-par-departement/">data.gouv.fr</a> - développé par <a target="_blank" href="https://trinketmage.github.io/">Rémi Tran</a>
    </div>
  </div>
</template>

<script>
import { ref, computed, inject, onMounted } from "vue";
import {
  highlightShape,
  heads,
  currentShapeType,
  currentRound,
  offset,
  activeShape,
  computeRowData
} from '@/pure/data.legislatives.gouv'
import gsap from "gsap";

import PanelData from "@/components/PanelData.vue";
import Macaron from "@/components/Macaron.vue";
import Highlight from "@/components/Highlight.vue";
import Layers from "@/components/Layers.vue";
import Close from "@/components/Close.vue";

export default {
  name: 'HomeView',
  components: {
    PanelData,
    Macaron,
    Highlight,
    Layers,
    Close
  },
  setup() {
    const title = ref(null);
    const panel = ref(null);
    const nav = ref(null);
    const graphics = inject('graphics');
    const sortedHeads = computed(() => {
      return heads.filter(_ => _.count > 0).sort((a, b) =>{
        return b.count - a.count
      })
    });
    const initTl = new gsap.timeline();
    const wording = computed(() => {
      return currentShapeType.value === 'departement' ? "département" : "circonscription"
    });
    const handleChange = () => {
      graphics.output.components.map.setLayer(currentShapeType.value, currentRound.value);
    }
    onMounted(() => {
      initTl.from(title.value.querySelectorAll("span"), {
        opacity: 0,
        y: 50,
        stagger: 0.2,
        duration: 0.628
      }, 'a+=1');
    });
    const handleEnter = (el, done) => {
      const links = el.querySelectorAll(".heads a");
      const tl = gsap.timeline({
        onComplete: done
      })
      if (links.length !== 0) {
        tl.from(links, {
          overwrite: true,
          opacity: 0,
          duration: 0.5,
          x: 30,
          stagger: 0.1
        }, `a+=${initTl.progress() < 1 ? 1.25 : 0 }`);
      } else {
        tl.from(el, {
          overwrite: true,
          opacity: 0
        }, `a`);
      }
    }
    const handleLeave = (el, done) => {
      const links = el.querySelectorAll(".heads a");
      const tl = gsap.timeline({
        onComplete: done
      })
      if (links.length !== 0) {
        tl.to(links, {
          overwrite: true,
          opacity: 0,
          duration: 0.5,
          x: 30,
          stagger: 0.05
        }, `a`);
      } else {
        tl.to(el, {
          overwrite: true,
          opacity: 0
        }, `a`);
      }
    }
    const closeFocus = (e) => {
      e.preventDefault();
      e.stopPropagation();
      activeShape.value = null;
    }
    const currentFocusData = computed(() => {
      if (activeShape.value !== null) {
        return computeRowData(graphics.output.components.map.active.data[activeShape.value].userData.data);
      } else {
        return null;
      }
    })
    const prevent = (e) => {
      e.preventDefault();
      e.stopPropagation();
    }
    const focusLabel = computed(() => {
      const { data } = graphics.output.components.map.active.data[activeShape.value].userData;
      const circonscription = data['Libellé circonscription législative'];
      return `${data['Libellé département']}  ${circonscription ? '- ' + circonscription : ""}`
    })
    const handleChangeRound = () => {
      graphics.output.components.map.setLayer(currentShapeType.value, currentRound.value);
    }
    const departementAttributes = computed(() => {
      return {
        // disabled: currentRound.value != ""
        disabled: false
      }
    })
    const secondAttributes = computed(() => {
      return {
        // disabled: currentShapeType.value === "departement"
        disabled: false
      }
    })
    return {
      title,
      panel,
      nav,
      highlightShape,
      sortedHeads,
      offset,
      currentShapeType,
      activeShape,
      handleChange,
      wording,
      handleEnter,
      handleLeave,
      closeFocus,
      currentFocusData,
      prevent,
      focusLabel,
      handleChangeRound,
      currentRound,
      departementAttributes,
      secondAttributes
    }
  }
}
</script>

<style lang="scss" scoped>
.home {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-bottom: calc(2.5vw + 34px);
  box-sizing: border-box;
}
h1 {
  margin: 0;
  font-family: 'Monument';
  font-size: 4.25vw;
  text-align: center;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #f3ece0;
  span {
    display: inline-block;
  }
  @include respond-to("xs-down") {
    font-size: 6.75vw;
    .long {
      display: none;
    }
  }
}
.heads {
  display: flex;
  flex-direction: column;
  padding: 3.5vw 1.25vw;
  position: relative;
  --color: #fff;
  @include respond-to("xs-down") {
    display: none;
  }
  ul {
    list-style: none;
    padding: 0;
  }
}
.head {
  margin: 5px 0;
  display: inline-flex;
  align-items: center;
  gap: 10px;
  text-decoration: none;
  color: inherit;
  align-self: flex-start;
  .vignette {
    width: 82px;
    height: 82px;
    border-radius: 50%;
    border-width: 2px;
    border-style: solid;
    border-color: var(--color);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  img {
    width: 78px;
    height: 78px;
    border-radius: 50%;
    font-size: 0;
    display: block;
    background-color: var(--color);
  }
  h2 {
    font-size: 20px;
    margin: 0;
    font-weight: 400;
    text-shadow: 0 0 1px #f3ece0;
  }
  .headline {
    font-weight: 900;
  }
  .small {
    font-weight: 400;
    font-size: 14px;
  }
}

.footer {
  position: fixed;
  bottom: 0;
  right: 0;
  padding: 0.5vw;
  font-size: 11px;
}
.sub-title {
  display: block;
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 1.25vw;
  font-family: 'Monument';
  text-transform: uppercase;
  font-size: 24px;
  z-index: 1;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #f3ece0;
  .selection {
    position: relative;
    padding: 0px 10px;
    border: 2px solid #202122;
    -webkit-text-stroke-width: 0px;
    background-color: #f3ece0;
    white-space: nowrap;
    display: inline-flex;
    align-items: center;
    gap: .3em;

    .icon {
      display: inline-block;
      pointer-events: none;
    }
  }
  select {
    position: absolute;
    -webkit-text-stroke-width: 0px;
    cursor: pointer;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
    &:focus-within ~ .icon {
      transform: translateY(2px) rotate(180deg);
    }
  }

  @include respond-to("xs-down") {
    padding: 16px 16px 20px;
    font-size: 13px;
    line-height: 22px;
  }
}
.list-move, /* apply transition to moving elements */
.list-enter-active,
.list-leave-active {
  transition: all 0.5s ease;
}

.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: translateX(30px);
}

/* ensure leaving items are taken out of layout flow so that moving
   animations can be calculated correctly. */
.list-leave-active {
  position: absolute;
}
</style>
