<template>
  <div class="panel-data">
    <h2>{{ name }}</h2>
    <ul>
      <li
        :key="code"
        v-for="({ code, nom, nuance, color, voixExprimesLabel, voixExprimes, sexe }, i) in data"
        :style="{
          '--color': color
        }"
      >
        <!-- <Macaron /> -->
        <div class="details">
          <div class="context">
            <div class="nom" v-if="nom">{{ nom }}</div>
            <div class="nuance">{{ nuance }}</div>
          </div>
          <div v-if="nom">
            <div class="label unsigned" v-if="voixExprimes > 1250 && !elected && nom && currentRound === '1er'">Qualifié{{ sexe === "FEMININ" ? 'e' : ''}}</div>
            <div class="label unsigned" v-else-if="(voixExprimes > 5000 && nom && currentRound === '1er') || (i === 0 && (currentRound == '2d' || currentRound == '2d1er'))">Élu{{ sexe === "FEMININ" ? 'e' : ''}}</div>
          </div>
        </div>
        <div class="stats">
          <div
            class="bar"
            :style="{
              '--width': `${voixExprimes * 0.01}%`
            }"
          ></div>
          <div class="label">{{ voixExprimesLabel }}</div>
        </div>

      </li>
    </ul>
  </div>
</template>

<script>
import { computed } from "vue";
import { currentRound } from "@/pure/data.legislatives.gouv";
export default {
  name: 'PanelData',
  // components: { Macaron },
  props: {
    name: String,
    data: Object
  },
  setup(props) {
    const elected = computed(() => {
      return !!props.data.find(_ => _.voixExprimes > 5000);
    });
    return {
      elected,
      currentRound
    }
  }
}
</script>

<style scoped lang="scss">
.panel-data {
  border: 2px solid #202122;
  background-color: rgba(243, 236, 224, 0.7);
  padding: 12px;
  --color: #DCDEE8;
  width: 320px;
  margin: auto 1.25vw;
  z-index: 1;
}
h2 {
  margin-top: 0;
  margin-bottom: 10px;
  font-family: "Monument";
  font-size: 17px;
}
ul {
  width: 100%;
  list-style: none;
  padding: 0;
  display: flex;
  gap: 10px;
  flex-direction: column;
}
.details {
  display: flex;
  gap: 10px;
  .context {
    flex: 1;
  }
  margin-bottom: 4px;
}
.nom {
  font-weight: 900;
}
.stats {
  display: flex;
  display: flex;
  align-items: center;
  gap: 10px;
}
.label {
  background-color: grey;
  width: 64px;
  text-align: center;
  color: white;
  border-radius: 4px;
  background-color: var(--color);
  height: 20px;
  align-self: flex-end;
  font-size: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
  &.unsigned {
    background-color: rgb(243, 236, 224);
    border: 1px solid #202122;
    color: #202122;
    font-weight: 900;
  }
}
.bar {
  flex: 1;
  width: 100%;
  background-color: #d3c0af;
  height: 4px;
  position: relative;

  &:after {
    content: "";
    display: block;
    width: var(--width);
    height: 100%;
    background-color: var(--color);
    
  }
}
</style>
