export default [
    {
        "code": "EXG",
        "nuance": "Extrême gauche",
        "url": "https://fr.wikipedia.org/wiki/Extr%C3%AAme_gauche_en_France"
    },
    {
        "code": "COM",
        "nuance": "Parti communiste français",
        "url": "https://fr.wikipedia.org/wiki/Parti_communiste_fran%C3%A7ais"
    },
    {
        "code": "FI",
        "nuance": "La France insoumise",
        "url": "https://fr.wikipedia.org/wiki/La_France_insoumise"
    },
    {
        "code": "SOC",
        "nuance": "Parti socialiste",
        "url": "https://fr.wikipedia.org/wiki/Parti_socialiste_(France)"
    },
    {
        "code": "RDG",
        "nuance": "Parti radical de gauche",
        "url": "https://fr.wikipedia.org/wiki/Parti_radical_de_gauche"
    },
    {
        "code": "VEC",
        "nuance": "Les Ecologistes",
        "url": "https://fr.wikipedia.org/wiki/Les_%C3%89cologistes"
    },
    {
        "code": "DVG",
        "nuance": "Divers gauche",
        "color": "#ffc0c0",
        "url": "https://fr.wikipedia.org/wiki/Divers_gauche"
    },
    {
        "code": "UG",
        "nuance": "Union de la gauche",
        "color": "#C02631",
        "url": "https://fr.wikipedia.org/wiki/Nouveau_Front_populaire"
    },
    {
        "code": "ECO",
        "nuance": "Ecologistes",
        "color": "#78b82b",
        "url": "https://fr.wikipedia.org/wiki/%C3%89cologisme"
    },
    {
        "code": "REG",
        "nuance": "Régionaliste",
        "color": "#df6d14",
        "url": "https://fr.wikipedia.org/wiki/R%C3%A9gionalisme_(politique)"
    },
    {
        "code": "DIV",
        "nuance": "Divers",
        "color": "#eeeeee",
        "url": "https://fr.wikipedia.org/wiki/Divers"
    },
    {
        "code": "REN",
        "nuance": "Renaissance",
        "url": "https://fr.wikipedia.org/wiki/Renaissance_(parti)"
    },
    {
        "code": "MDM",
        "nuance": "Modem",
        "url": "https://fr.wikipedia.org/wiki/Mouvement_d%C3%A9mocrate_(France)"
    },
    {
        "code": "HOR",
        "nuance": "Horizons",
        "color": "#0001B8",
        "url": "https://fr.wikipedia.org/wiki/Horizons"
    },
    {
        "code": "ENS",
        "nuance": "Ensemble ! (Majorité présidentielle)",
        "color": "#62c3a7",
        "url": "https://fr.wikipedia.org/wiki/Ensemble_pour_la_R%C3%A9publique_(France)"
    },
    {
        "code": "DVC",
        "nuance": "Divers centre",
        "color": "#FAC577",
        "url": "https://fr.wikipedia.org/wiki/Divers_centre"
    },
    {
        "code": "UDI",
        "nuance": "Union des Démocrates et Indépendants",
        "color": "#00FFFF",
        "url": "https://fr.wikipedia.org/wiki/Union_des_d%C3%A9mocrates_et_ind%C3%A9pendants"
    },
    {
        "code": "LR",
        "nuance": "Les Républicains",
        "color": "#0066CC",
        "url": "https://fr.wikipedia.org/wiki/Les_R%C3%A9publicains"
    },
    {
        "code": "DVD",
        "nuance": "Divers droite",
        "color": "#adc1fd",
        "url": "https://fr.wikipedia.org/wiki/Divers_droite"
    },
    {
        "code": "DSV",
        "nuance": "Droite souverainiste",
        "url": "https://fr.wikipedia.org/wiki/Droite_souverainiste"
    },
    {
        "code": "RN",
        "nuance": "Rassemblement National",
        "color": "#002e61",
        "url": "https://fr.wikipedia.org/wiki/Rassemblement_national"
    },
    {
        "code": "REC",
        "nuance": "Reconquête !",
        "url": "https://fr.wikipedia.org/wiki/Reconqu%C3%AAte_(parti_politique)"
    },
    {
        "code": "UXD",
        "nuance": "Union de l'extrême droite",
        "color": "#001d50",
        "url": "https://fr.wikipedia.org/wiki/Union_de_l%27extr%C3%AAme_droite"
    },
    {
        "code": "EXD",
        "nuance": "Extrême droite",
        "color": "#404040",
        "url": "https://fr.wikipedia.org/wiki/Extr%C3%AAme_droite"
    }
];
