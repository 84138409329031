<template>
  <div
    class="highlight"
  >
    <div
      class="label"
      :style="{
        '--left': data.centroid[0] + 'px',
        '--top': -data.centroid[1] + 'px'
      }"
    >
      <div class="helper" v-if="data.nom">
        {{ `${data.nom} ${data.code}` }}
      </div>
      <div class="helper" v-else>
        {{ `${data.nomDepartement} - ${data.nomCirconscription}` }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HighlightComponent',
  props: {
    data: Object
  }
}
</script>

<style scoped lang="scss">
.highlight {
  position: fixed;
  top: 50%;
  left: 50%;
  --offsetX: 0px;
  --offsetY: 0px;
  z-index: 0;

  .label {
    position: absolute;
    margin-top: -20px;
    font-size: 13px;
    transform: translateX(calc(var(--left) * var(--scale) + var(--offsetX))) translateY(calc(var(--top) * var(--scale) + var(--offsetY)));
    will-change: transform;
      &:before {
        display: block;
        content: "";
        width: 29px; 
        height: 1px; 
        background-color: black;
        position: absolute;
        left: 0%;
        bottom: 0%;
        margin-right: -1px;
        margin-top: -1px;
        transform: rotate(-45deg);
        transform-origin: 0% 50%;
        z-index: 1;
      }
    .helper {
      transform: translateX(20px) translateY(-20px);
      border: 1px solid #f3ece0;
      color: #f3ece0;
      padding: 4px 8px;
      position: relative;
      white-space: nowrap;
      background-color: black;
    }
  }
}
</style>
