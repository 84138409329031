import {
  LoadingManager,
  TextureLoader,
} from "three";

import * as d3 from "d3";

class Assets {
  manager = new LoadingManager(); 
  textureLoader = new TextureLoader(this.manager);
  constructor() {
    // cellular_round_ff_fractal.jpg
  }
  async loadTopology(url) {
    const response = await fetch(url);
    const topology = await response.json();
    return topology;
  }
  async loadFRData(url) {
    const response = d3.dsv(";", url);
    return response;
  }
  async loadData(url) {
    const response = d3.dsv(",", url);
    return response;
  }
}

export default new Assets();
