import Common from "@/graphics/Common";
// import Assets from "@/graphics/Assets";
import Device from "@/pure/Device";

import Map from "@/graphics/components/Map.js";
// import Postprocessing from "@/graphics/postprocessing";

export default class Output {
  components = {};
  postProcess = false;

  constructor() {
    this.init();
  }

  async init() {
    // Assets.init();
    // Common.scene.add(data);
    this.components.map = new Map();
    // this.components.postprocessing = new Postprocessing();
  }

  updatePage(progress) {
    const x = -(Device.fov.width + Device.fov.height * 0.75 + 9);

    this.page.position.x = x * progress;
    this.page.updateMatrix();
  }
  
  render(t, delta) {
    Object.keys(this.components).forEach(_ => {
      this.components[_].render(t, delta);
    });
    // Common.renderer.setRenderTarget(null);
    Common.render();
  }

  disposeComponent(_) {
    this.page.remove(this.components[_]);
    this.components[_].dispose();
    delete this.components[_];
  }

  dispose() {
    Object.keys(this.components).forEach(_ => {
      this.disposeComponent(_);
    });
    
    Common.scene.remove(this.page);
    this.page = null;
    
  }

  resize() {
    Object.keys(this.components).forEach(_ => {
      this.components[_].resize();
    });
  }

  setDebug() {
    Object.keys(this.components).forEach(_ => {
      if ('setDebug' in this.components[_]) {
        this.components[_].setDebug(Common.debug);
      }
    });
  }
}
