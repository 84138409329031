import {
    Group,
    Mesh,
    MeshBasicMaterial,
    Vector2,
    Vector3,
    Raycaster,
    LineSegments,
    LineBasicMaterial,
    Color,
    RawShaderMaterial,
    GLSL3
} from "three";
import * as d3 from "d3";
// import {rewind} from "@turf/rewind"
import * as topojson from "topojson-client";
import gsap from "gsap";

import Common from "@/graphics/Common";
import Assets from "@/graphics/Assets";
import Device from "@/pure/Device";

import ShapeFactory from "@/graphics/factory/shape";

import {
    getNuance,
    // getDataFromCode,
    highlightShape,
    currentShapeType,
    heads,
    offset,
    nuances,
    activeShape,
    currentRound,
    showLines
} from "@/pure/data.legislatives.gouv";

import vertexShader from "./vertex.glsl";
import fragmentShader from "./fragment.glsl";

export default class Map extends Group {
    PARAMS = {
        fontScale: 0.009,
        groundColor: 0xd3c0af
    };
    data = [];
    allLines = [];

    userData = {
        departement: {
            "1er": {
                loaded: false
            },
            "2d": {
                loaded: false
            }
        },
        circonscription: {
            "1er": {
                loaded: false
            },
            "2d": {
                loaded: false
            },
            "2d1er": {
                loaded: false
            },
        },
    };

    zoom = d3.zoom()
        .extent([[0, 0], [Device.viewport.width, Device.viewport.height]])
        .scaleExtent([0.9, 12])
        .on("zoom", this.zoomed.bind(this));

    lineMaterial = new LineBasicMaterial({ color: 0xa3aaaa, transparent: true });

    interactives = [];
    raycaster = new Raycaster();
    pointer = new Vector2();
    INTERSECTED = null;

    material2 = new RawShaderMaterial({
        glslVersion: GLSL3,
        transparent: true,
        uniforms: {
            resolution: {
                value: new Vector3(Device.viewport.width, Device.viewport.height, Device.viewport.width / Device.viewport.height)
            },
            time: {
                value: 0
            },
            iIdx: {
                value: -1
            },
            noise: {
                value: Assets.textureLoader.load("/assets/cellular_round_ff_fractal.jpg")
            },
            transition: {
                value: 1.0
            },
            angle: {
                value: 1.14
            }
        },
        vertexShader,
        fragmentShader,
    });

    constructor() {
        super();
        Common.scene.add(this);
        this.material = new MeshBasicMaterial({ transparent: true, vertexColors: true });
        this.init();
        this.resize();
    }
    async init() {
        const EUfeatures = await Assets.loadTopology("/assets/europe-50m.json");
        const EUShapes = [];
        for (const feature of EUfeatures.features.filter(_ => _.id !== 'FR')) {
            let shape = ShapeFactory.createShape(feature.geometry, new Color(this.PARAMS.groundColor).getHex());
            EUShapes.push(shape);
        }
        this.EUMesh = this.mergeShapes(EUShapes, this.material2.clone());
        // EUMesh.layers.enable( 1 );
        this.add(this.EUMesh);
        this.tl = new gsap.timeline();
        this.tl.add(this.enter(this.EUMesh.material), 'a');

        // this.data = await Assets.loadFRData("/assets/resultats-definitifs-par-departement.csv");
        
        this.data = await Assets.loadFRData("https://static.data.gouv.fr/resources/elections-legislatives-des-30-juin-et-7-juillet-2024-resultats-definitifs-du-1er-tour/20240710-171330/resultats-definitifs-par-departements.csv");
        this.datadepartement2d = await Assets.loadFRData("https://static.data.gouv.fr/resources/elections-legislatives-des-30-juin-et-7-juillet-2024-resultats-definitifs-du-2nd-tour/20240710-170553/resultats-definitifs-par-departement.csv");
        this.datacirconscription1er = await Assets.loadFRData("https://static.data.gouv.fr/resources/elections-legislatives-des-30-juin-et-7-juillet-2024-resultats-definitifs-du-1er-tour/20240710-171413/resultats-definitifs-par-circonscriptions-legislatives.csv");
        this.datacirconscription2d = await Assets.loadFRData("https://static.data.gouv.fr/resources/elections-legislatives-des-30-juin-et-7-juillet-2024-resultats-definitifs-du-2nd-tour/20240710-170728/resultats-definitifs-par-circonscription.csv");

        const topology = await Assets.loadTopology("/assets/departements.topojson");
        let features = topojson.feature(topology, topology.objects.departements);
        const features3 = await Assets.loadTopology("/assets/circonscriptions-rewind-simplified_20.geojson");

        this.userData.departement["1er"] = this.buildLayer({
            features: features,
            dataMatcher: function(_) {
                let codeDepartement = _['Code département'];
                if (codeDepartement.length === 1) {
                    codeDepartement = '0' + codeDepartement;
                }
                return codeDepartement === this.properties.code
            },
            currentData: this.data
        });
        this.userData.departement["2d"] = this.buildLayer({
            features: features,
            dataMatcher: function(_) {
                let codeDepartement = _['Code département'];
                if (codeDepartement.length === 1) {
                    codeDepartement = '0' + codeDepartement;
                }
                return codeDepartement === this.properties.code
            },
            currentData: this.datadepartement2d
        });
        // DANGER ZONE
        this.userData.departement["2d1er"] = this.userData.departement["2d"];

        this.userData.circonscription["1er"] = this.buildLayer({
            features: features3,
            dataMatcher: function(_) { 
                let codeCirconscription = _['Code circonscription législative'];
                if (codeCirconscription.length === 3) {
                    codeCirconscription = '0' + codeCirconscription;
                }
                return codeCirconscription === this.properties["codeCirconscription"]
            },
            currentData: this.datacirconscription1er
        });
        this.userData.circonscription["2d"] = this.buildLayer({
            features: features3,
            dataMatcher: function(_) {
                let codeCirconscription = _['Code circonscription législative'];
                if (codeCirconscription.length === 3) {
                    codeCirconscription = '0' + codeCirconscription;
                }
                return codeCirconscription === this.properties["codeCirconscription"]
            },
            currentData: this.datacirconscription2d
        });
        this.userData.circonscription["2d1er"] = this.buildLayer({
            features: features3,
            dataMatcher: function(_) {
                let codeCirconscription = _['Code circonscription législative'];
                if (codeCirconscription.length === 3) {
                    codeCirconscription = '0' + codeCirconscription;
                }
                return codeCirconscription === this.properties["codeCirconscription"]
            },
            currentData: this.datacirconscription2d,
            fallbackData: this.datacirconscription1er
        });


        const lines3 = this.createLines(features3.features);
        lines3.material = this.lineMaterial.clone();
        lines3.material.color = new Color(0x000000);
        lines3.material.opacity = 0.0;
        // this.add(lines3);
        const lines = this.createLines(features.features);
        lines.material.opacity = 0.0;
        // this.add(lines);
        this.allLines = [lines3, lines];

        this.setLayer(currentShapeType.value, currentRound.value);

        this.tl.add(
            this.enter(this.material2)
        , 'a+=0.5');
        this.tl.add(
            this.fadeIn(lines.material)
        , 'a+=1');
        this.tl.add(
            this.fadeIn(lines3.material, .4)
        , 'a+=3');

        this.boundPointermove = this.handlePointermove.bind(this);
        document.addEventListener("pointermove", this.boundPointermove, false);
        this.boundPointerdown = this.handlePointerdown.bind(this);
        document.addEventListener("pointerdown", this.boundPointerdown, false);
        this.boundPointerup = this.handlePointerup.bind(this);
        document.addEventListener("pointerup", this.boundPointerup, false);

        this.d3Handle = d3.select(document.body);
        this.d3Handle.call(
            this.zoom,
        );
        this.d3Handle.call(this.zoom.transform, d3.zoomIdentity.translate(Device.viewport.width * 0.5, Device.viewport.height * 0.5));
    }
    showLines() {
        this.allLines.forEach(_ => {
            this.add(_);
        });
    }
    hideLines() {
        this.allLines.forEach(_ => {
            this.remove(_);
        });
    }
    setLayer(layer, round) {
        console.log("remove");
        this.hideLines();
        Object.entries(this.userData).forEach(layer => {
            Object.entries(layer[1]).forEach((entry) => {
                this.remove(entry[1].mesh);
            })
        });
        this.active = this.userData[layer][round];
        this.interactives = [this.active.mesh];
        this.add(this.active.mesh);
        this.setHeads();
        if (showLines.value) {
            this.showLines();
        }
    }
    buildLayer(properties) {
        const data = this.makeShape(properties);
        const mesh = this.mergeShapes(data);
        return {
            data,
            mesh
        }
    }
    makeShape({ features, dataMatcher, currentData, fallbackData }) {
        const shapes = [];
        for (const feature of features.features) {
            let data = currentData.find(dataMatcher.bind(feature));
            if (!data && fallbackData) {
                data = fallbackData.find(dataMatcher.bind(feature));
            }
            if (!data) {
                console.warn(feature.properties, feature.properties.nomDepartement, feature.properties.nomCirconscription);
            } else {
                const topVote = getNuance(data);            
                const nuance = nuances.find(_ => _.code === topVote);
                
                let shape = ShapeFactory.createShape(feature.geometry, new Color(nuance.color).getHex(), shapes.length);
                shape.userData = {...feature.properties};
                const centroid = ShapeFactory.geoPath.centroid(feature.geometry);
                const bounds = ShapeFactory.geoPath.bounds(feature.geometry);
                shape.userData.centroid = centroid;
                shape.userData.bounds = bounds;
                shape.userData.topVote = topVote;
                shape.userData.nuance = nuance;
                shape.userData.data = data;
                shapes.push(shape);
            }
        }
        return shapes;
    }
    setHeads() {
        heads.splice(0);
        const uniqueVoted = [];
        this.active.data.forEach(({ userData }) => {
            const { topVote, nuance } = userData;
            const found = uniqueVoted.find(_ => _.code === topVote);
            if (!found) {
                uniqueVoted.push({
                    ...nuance,
                    count: 1
                });
            } else {
                found.count += 1;
            }
        });
        heads.push(...uniqueVoted);
    }
    createLines(features) {
        const geometries = [];
        for (const feature of features) {
            let line = ShapeFactory.createLine(feature.geometry);
            geometries.push(line);
        }
        return this.mergeLines(geometries);
    }
    mergeLines(shapes) {
        const geom = ShapeFactory.mergeShapes(shapes);
        const mesh = new LineSegments(geom, this.lineMaterial);
        return mesh;
    }
    mergeShapes(shapes, material = this.material2) {
        const geom = ShapeFactory.mergeShapes(shapes);
        const mesh = new Mesh(geom, material);
        return mesh;
    }
    handlePointermove(event) {
        event.preventDefault();
        this.pointer.set(
            (event.clientX / Device.viewport.width) * 2 - 1,
            - (event.clientY / Device.viewport.height) * 2 + 1
        );

        this.raycaster.setFromCamera(this.pointer, Common.camera);

        let intersects = this.raycaster.intersectObjects(this.interactives);

        if (intersects.length > 0) {
            document.body.style.cursor = 'pointer';

            const intersect = intersects[0];
            const { faceIndex, object } = intersect;
            const { geometry } = object;
            const { idx } = geometry.attributes;
            const id = idx.array[geometry.index.array[faceIndex * 3]];
            const shape = this.active.data[id];
            // const shape = this.circonscriptionsShapes[id];
            const { userData } = shape;
            highlightShape.value = userData;
            this.active.mesh.material.uniforms.iIdx.value = id;
        } else {
            highlightShape.value = null;
            this.material2.uniforms.iIdx.value = -1;
        }
    }
    handlePointerdown(event) {
        this.lastPointdown = event.timeStamp;
    }
    handlePointerup(event) {
        if (event.timeStamp - this.lastPointdown > 300) {
            return;
        }
        this.pointer.set(
            (event.clientX / Device.viewport.width) * 2 - 1,
            - (event.clientY / Device.viewport.height) * 2 + 1
        );

        this.raycaster.setFromCamera(this.pointer, Common.camera);
        const intersects = this.raycaster.intersectObjects(this.interactives, false);

        if (intersects.length > 0) {
            const intersect = intersects[0];
            const { faceIndex, object } = intersect;
            const { geometry } = object;
            const { idx } = geometry.attributes;
            const id = idx.array[geometry.index.array[faceIndex * 3]];
            const { userData } =  this.active.data[id];
            this.center(userData.bounds);
            activeShape.value = id;
        }
    }
    center(bounds) {
        const [[x0, y0], [x1, y1]] = bounds;
        const scale = Math.min(
            12,
            0.9 / Math.max((x1 - x0) / (Device.viewport.width * 0.5), (y1 - y0) / (Device.viewport.height * 0.5))
        );
        const x = -(x0 + x1) / 2 * scale;
        const y = -(y0 + y1) / 2 * scale;
        const PARAMS = {
            x: this.position.x,
            y: this.position.y,
            scale: this.scale.x
        }
        gsap.to(PARAMS, {
            x,
            y,
            scale,
            duration: 0.5,
            ease: "sine.inOut",
            onUpdate: () => {
                this.d3Handle.call(
                    this.zoom.transform,
                    d3.zoomIdentity.translate(PARAMS.x + Device.viewport.width * 0.5, -PARAMS.y + Device.viewport.height * 0.5).scale(PARAMS.scale)
                );
            }
        });

    }
    zoomed(e) {
        const {transform} = e;
        const { k, x, y } = transform;
        this.scale.set(k, k, 1);
        this.position.x = x - Device.viewport.width * 0.5
        this.position.y = -y + Device.viewport.height * 0.5
        offset.x = this.position.x + 'px';
        offset.y = -this.position.y + 'px';
        offset.scale = k;
    }
    fadeIn(material, value = 1) {
        const lerp = {
            x: 0,
        }
        return gsap.to(lerp, {
            x: value,
            duration: 1,
            onUpdate: () => {
                material.opacity = lerp.x;
            }
        })
    }
    enter(material) {
        const lerp = {
            x: 1,
        }
        return gsap.to(lerp, {
            x: 0,
            duration: 4,
            onUpdate: () => {
                material.uniforms.transition.value = lerp.x;
            }
        })
    }
    render(t) {
        this.material2.uniforms.time.value = t * 0.025;
        if (this.EUMesh) this.EUMesh.material.uniforms.angle.value = t * 100;
    }
    resize() {
        this.material2.uniforms.resolution.value.set(Device.viewport.width, Device.viewport.height, Device.viewport.width / Device.viewport.height);
    }
    dispose() {
        document.removeEventListener("pointermove", this.boundPointermove);
        document.removeEventListener("pointerdown", this.boundPointerdown);
        document.removeEventListener("pointerup", this.boundPointerup);
        Common.scene.remove(this);
    }
}