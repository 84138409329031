import { ref, reactive } from "vue";
import nuances from "@/pure/nuances.legislatives";

export { nuances };

export const offset = reactive({ x: '0px', y: '0px', scale: 1 });
export const highlightShape = ref(null);
export const activeShape = ref(null);
// export const currentShapeType = ref("departement");
export const currentShapeType = ref("circonscription");
export const currentRound = ref("2d1er");
export const showLines = ref(true);
export const heads = reactive([]);

const length = 15;

export const getTopVote = function(row) {
    const list = [];
    for(let i = 0; i < length; i++) {
        let str = row[`% Voix/exprimés ${i + 1}`].replace(",", ".").replace("%", "");
        if (str === "") {
            str = '0';
        }
        str = Number.parseFloat(str) * 0.01;
        list.push(str);
    }
    const highest = Math.max(...list);

    const idx = list.findIndex(_ => _ === highest);
    return idx;
}

export const getNuance = function(row) {
    const list = [];
    for(let i = 0; i < length; i++) {
        let value = row[`% Voix/exprimés ${i + 1}`];
        if (!value) {
            value = '';
        }
        let str = value.replace(",", ".").replace("%", "");
        if (str === "") {
            str = '0';
        }
        str = Number.parseFloat(str) * 0.01;
        list.push(str);
    }
    const highest = Math.max(...list);

    const idx = list.findIndex(_ => _ === highest);
    // console.log(row, idx);
    const nuance = row[`Nuance candidat ${idx + 1}`];
    return nuance;
}

export const getDataFromCode = function(data, code, key) {
    // console.log(code);
    const row = data.find(_ => _[key] === code);
    const nuance = nuances.find(_ => _.code === getNuance(row));
    return nuance;
}

export const computeRowData = function(row) {
    const candidats = [];
    for(let i = 1; i < 512; i++) {
        const nuance = row[`Nuance candidat ${i}`];
        if (!nuance) {
            break;
        }
        let nom = null;
        const name = row[`Nom candidat ${i}`];
        const surname = row[`Prénom candidat ${i}`];
        const sexe = row[`Sexe candidat ${i}`];
        const voixExprimes = Number.parseInt(row[`% Voix/exprimés ${i}`].replace(",", "").replace("%", ""));
        const nuanceData = nuances.find(_ => _.code === nuance);  
        if (name) {
            nom = `${surname} ${name}`
        }
        candidats.push({
            code: nuance,
            nuance: nuanceData.nuance,
            nom,
            sexe,
            voixExprimes,
            voixExprimesLabel: row[`% Voix/exprimés ${i}`],
            color: nuanceData.color
        });
    }
    return candidats.sort((a, b) =>{
        return b.voixExprimes - a.voixExprimes
    });
}
